

.page{
    margin-left: 5%;
    margin-right: 5%;
}

.header{
    padding-top: .5rem;

}

.body{
    margin-left: 5%;
    margin-right: 5%;
    padding-bottom: 5rem;
}