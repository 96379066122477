.tablediv{
    max-height: 30rem;
    overflow: auto;

}


.thead{

}

.th{
    background-color: white;
    position: sticky;
    top: 0;
    z-index: 1;
}

.tablediv::-webkit-scrollbar{
    width:5px;
    border: 5px solid #dbdbdb;
    border-radius: 4px;
}




.tablediv::-webkit-scrollbar-thumb{
    background-color: #228BE6;
    border-radius: 4px;
}