.modalBody{
    padding-bottom: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
}

.buttonDiv{
    padding-top: 2rem;
}

.saveButton{
    margin-left: 1rem;
}