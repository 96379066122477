.userInputDiv{
  columns: 2;
  column-width: auto;
}


.container2Columns {
  display: grid;
  grid-template-columns: 50% 50%; /* fraction*/
  padding-bottom: 2rem;
}

.singleColumn {
  /* padding-left: 1rem; */
  padding-bottom: 1rem;
  padding-left: .5rem;
  padding-right: .5rem;
  position: relative;
  /*position: relative*/
}

.centerVerticalDiv{
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.stepperContent{
  padding-top: 2rem;
}

.tabsContent{
  padding-top: 1rem;
}

.stepperContentFileInput{
  padding-top: 2rem;
  margin-left: 10%;
  margin-right: 10%;
  width: 20rem;
}


.buttonGroup{
  margin-top: .5rem;
}

.csvEvaluation{
  margin-top: 2rem;
}

.body{
  margin-left: 10%;
  margin-right: 10%;
}

.employeeTimeTable{
  margin-top: 0rem;
  padding-left: 2%;
  padding-right: 2%;
}

.textCenter{
  text-align: center;
}

.uploadNewCsvButton{
  margin-top: 10rem;
  margin-left: -3rem;
}

.btnExportAsExcel{
  margin-top: 1rem;
}

.btnExportAllAsExcel{
  margin-top: 2rem;
}

.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}